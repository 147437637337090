import logo from "./logo_black.png"

const  clientData = {
    client_entity: 87,
    attorney_firm: 'Rob Levine & Associates',
    attorney_name: 'Rob Levine & Associates',
    attorney_number: '(401) 386-3473',
    attorney_email: 'ertchelp@roblevine.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ERTC+Explainer+Video+-+UPDATED+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#2C3754',
    siteLink: 'https://roblevine.com/',
    logo
}

export default clientData